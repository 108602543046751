<template>
  <div>
    <v-select
        :value="value"
        :options="selectLocations"
        :filterable="false"
        label="name"
        @open="onOpen"
        @close="onClose"
        @search="(query) => (search = query)"
        :multiple="multiple"
        @input="(val) => $emit('input', val)"
        :readonly="readonly"
        :disabled="readonly"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          {{ $i18n.t("LoadingMoreOptions") }}
        </li>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import locationApi from "@/api/locations-api";

export default {
  components: {
    vSelect,
  },
  props: ['value', 'multiple', 'readonly', 'clients'],
  data() {
    return {
      observer: null,
      currentPage: 1,
      lastPage: 0,
      pageLength: 10,
      search: '',
      selectLocations: [],
      selected: '',
    }
  },
  computed: {
    ...mapGetters({
      currentCompany: 'companies/getCurrentCompany',
      currentClient: 'clients/getCurrentClient',
    }),
    hasNextPage() {
      let output = true
      if (this.lastPage === this.currentPage) {
        output = false
      }
      if (this.selectLocations.length === 0) {
        output = false
      }

      return output
    },
  },
  watch: {
    async search() {
      this.currentPage = 1
      this.selectLocations = []
      await this.chargeData(true)
    },
    async clients() {
      await this.chargeData(true)
    },
  },
  async mounted() {
    await this.chargeData()
    this.observer = new IntersectionObserver(this.infiniteScroll)

  },
  methods: {
    async handleSelectLocationsChange(newValue) {
      this.currentPage = 1
      this.selectLocations = []
      await this.chargeData()
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async chargeData(ifSearch = false) {
      let clients = this.currentClient ? [this.currentClient.id] : []
      if (this.clients) {
        clients = []
        await this.clients.forEach(async e => {
          clients.push(e.id)
        })
      }

      await locationApi.getList(clients, this.currentPage, this.pageLength, this.search)
        .then(
          response => {
            this.selectLocations = (ifSearch) ? response.data.data : this.selectLocations.concat(response.data.data)
            this.lastPage = response.data.meta.last_page
          },
          () => {
            console.log('ERROR')
          },
        )
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.currentPage += 1
        await this.chargeData()
        ul.scrollTop = scrollTop
      }
    },

  },
}
</script>
<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
.form-control:disabled{
  background-color: #f8f8f8 !important;
}
</style>
